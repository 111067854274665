import { Component } from "react"
import logo from "../images/Logo.png"
import appStoreDownloadImage from "../images/appStoreDownload.png"
import Footer from "./Footer"
import "../styles/Home.css"

class Home extends Component {

    appStoreAction = () => {
        window.location = "https://itunes.apple.com/de/app/appName/id1580087711?mt=8"
    }

    render() {
        return (
            <div className="home">

                <div className="content">
                    <div className="centeredDiv">
                        <img
                            className="logo"
                            src={logo}
                            alt="logo"
                        />

                        <div>
                            <button onClick={this.appStoreAction}>
                                <img
                                    className="downloadButton"
                                    src={appStoreDownloadImage}
                                    alt="app store download"
                                />
                            </button>
                        </div>

                        <h1>Koche nach Zutaten!</h1>
                    </div>
                </div>

                <Footer />
            </div>
        )
    }
}

export default Home