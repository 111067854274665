import { Component } from "react"
import { Link } from "react-router-dom"
import "../styles/Footer.css"

class Footer extends Component {

    render() {
        return (
            <div className="footer">
                <ul>
                    <li>
                        <Link to="/kontakt" style={{ textDecoration: 'none' }}>Kontakt</Link>
                    </li>
                    <li>
                        <Link to="/impressum" style={{ textDecoration: 'none' }}>Impressum</Link>
                    </li>
                    <li>
                    <Link to="/datenschutz" style={{ textDecoration: 'none' }}>Datenschutzerklärung</Link>
                    </li>
                </ul>
            </div>
        )
    }
}

export default Footer