import { Component } from "react"
import { Link } from "react-router-dom"

class Privacy extends Component {

    render() {
        return (
            <div className="info privacy">
                <h1>Datenschutz-Erklärung</h1>

                <p>Diese App (im Folgenden die "App") und diese Website (nachfolgend „Website“) wird bereitgestellt von Conrad Felgentreff (nachfolgend „uns“ oder „wir“). Weitere Informationen zum Anbieter der Website findest du im <Link to="/impressum">Impressum</Link>.</p>

                <h3>Umgang mit personenbezogenen Daten</h3>
                <p>Im Folgenden möchten wir dich über unseren Umgang mit personenbezogenen Daten im Rahmen der Nutzung unserer App und dieser Website informieren. Soweit in den folgenden Abschnitten nicht anders erläutert, folgt die Rechtsgrundlage für einen Umgang mit deinen personenbezogenen Daten aus der Erforderlichkeit dieses Umgangs für die Bereitstellung der von dir angefragten Funktionalitäten der App oder der Website (Art. 6(1)(b) Datenschutz-Grundverordnung).</p>

                <h2>Nutzung der App</h2>
                <h3>Kommunikation mit unserer App </h3>
                <p>Um die Funktionalitäten unserer App für dich bereitstellen zu können, wird diese bei jeder Nutzung durch dich die folgenden Informationen an uns übermitteln:</p>
                <ul>
                    <li>IP-Adresse</li>
                    <li>Datum und Uhrzeit des Zugriffs</li>
                    <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                    <li>Informationen zum Betriebssystem</li>
                </ul>
                <h3>Kostenpflichtige Abonnements und Probe-Abonnements</h3>
                <p>Neben der kostenlosen Nutzung der App hast du die Möglichkeit, ein kostenpflichtiges Abonnement abzuschließen oder eine Testphase für ein Abonnement in Anspruch zu nehmen. Dadurch hast du Zugriff auf einen erweiterten Funktionsumfang der App. Sobald du eine kostenlose Testphase für ein Abonnement oder ein kostenpflichtiges Abonnement über einen In-App-Kauf in Anspruch nehmen möchtest, werden Informationen zur Zahlungsweise, u.A. Kreditkartendaten und andere Informationen zum Finanzdienstleister über Apple erhoben und gespeichert. Die App selbst verarbeitet keine Informationen zu Kredit- oder Bankkartendaten. Zu den Zahlungs-Daten, die von den Plattform-Anbietern verarbeitet werden, erhalten wir keine personenbezogene Daten, lediglich:</p>
                <ul>
                    <li>Zeitpunkt des Abschlusses des Abonnements</li>
                    <li>Laufzeit des Abonnements</li>
                    <li>Art des Abonnements</li>
                </ul>

                <h3>Weitergabe von personenbezogenen Daten zur Verarbeitung in unserem Auftrag</h3>
                <p>Zur Verarbeitung deiner personenbezogenen Daten setzen wir in gewissem Rahmen spezialisierte Dienstleister ein. Unsere Dienstleister werden von uns sorgfältig ausgewählt und regelmäßig kontrolliert. Sie verarbeiten personenbezogene Daten nur in unserem Auftrag und strikt nach unseren Weisungen auf der Grundlage entsprechender Verträge über eine Auftragsverarbeitung.</p>
                <h3>Übermittlung von Daten an Dritte</h3>
                <p>Wir teilen deine personenbezogenen Daten mit folgenden Dritten für die folgenden Zwecke:</p>
                <div className="tableWrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Dienstleister</th>
                            <th>Zweck der Datenverarbeitung</th>
                            <th>Addresse</th>
                            <th>Details zur Datenverarbeitung</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Google</th>
                            <th>App Analytics</th>
                            <th>1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA</th>
                            <th>https://cloud.google.com/security/privacy</th>
                        </tr>
                        <tr>
                            <th>RevenueCat</th>
                            <th>Subscription Management Tool</th>
                            <th>633 Taraval St. Suite 101, San Francisco, CA, 94116</th>
                            <th>https://www.revenuecat.com/privacy</th>
                        </tr>
                    </tbody>
                </table>
                </div>

                <h3>Verarbeitung von personenbezogenen Daten außerhalb der EU / des EWR</h3>
                <p>Deine personenbezogenen Daten werden zum Teil auch in Ländern außerhalb der Europäischen Union („EU“) oder des Europäischen Wirtschaftsraums („EWR“) verarbeitet, wo möglicherweise ein niedrigeres Datenschutzniveau besteht als in Europa.</p>


                <h2>Nutzung der Website</h2>
                <h3>Aufruf der Website</h3>
                <p>Wenn du unsere Website aufrufst, überträgt dein Browser technisch bedingt bestimmte Informationen an unseren Webserver, um dir die von dir aufgerufenen Informationen zur Verfügung zu stellen. Um dir den Besuch der Website zu ermöglichen, werden dabei folgende Informationen erhoben, kurzfristig gespeichert und verwendet:</p>
                <ul>
                    <li>IP-Adresse</li>
                    <li>Datum und Uhrzeit der Anfrage</li>
                    <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                    <li>Inhalt der Anforderung (konkrete Seite)</li>
                    <li>Betriebssystem und dessen Oberfläche</li>
                    <li>Zugriffsstatus / HTTP-Statuscode</li>
                    <li>Übertragene Datenmenge</li>
                    <li>Website, von der die Anforderung kommt</li>
                    <li>Browser, Sprache und Version der Browsersoftware</li>
                </ul>
                <p>Zum Schutz unserer berechtigten Interessen werden wir die o.g. Angaben für eine begrenzte Zeit speichern, um im Falle eines unberechtigten Zugriffs eine Herleitung der personenbezogen Daten veranlassen zu können(Art. 6(1)(f) Datenschutz-Grundverordnung).</p>

                <h2>Informationen über deine Rechte</h2>
                <p>Die folgenden Rechte stehen dir aufgrund der anwendbaren Datenschutzgesetze zur Verfügung:</p>
                <ul>
                    <li>Recht auf Auskunft über die bei uns zu deiner Person gespeicherten Daten;</li>
                    <li>Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung deiner personenbezogenen Daten;</li>
                    <li>Recht auf Widerspruch gegen eine Verarbeitung, die unserem berechtigten Interesse, einem öffentlichen Interesse oder einem Profiling dient, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die deine Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen;</li>
                    <li>Recht auf Datenübertragbarkeit;</li>
                    <li>Recht sich bei einer Aufsichtsbehörde zu beschweren;</li>
                    <li>Von dir erteilte Einwilligungen zur Verarbeitung deiner personenbezogenen Daten kannst du jederzeit mit Wirkung für die Zukunft widerrufen. Nähere Informationen hierzu findest du in den jeweiligen Abschnitten oben, wo eine Datenverarbeitung auf Grundlage deiner Einwilligung beschrieben wird.</li>
                </ul>
                <p>Falls du von deinen Rechten Gebrauch machen möchtest, richte bitte dein Anliegen an den unten genannten Ansprechpartner (siehe Kontakt).</p>
                <h3>Kontakt</h3>
                <p>Für dein Anliegen zum Datenschutz schreibe bitte dem Betreiber der App und der Webseite (siehe <Link to="/impressum">Impressum</Link>).</p>

                <h3>Anpassung der Datenschutzerklärung</h3>
                <p>Wir behalten uns vor, diese Datenschutzerklärung von Zeit zu Zeit zu aktualisieren. Aktualisierungen dieser Datenschutzerklärung werden auf unserer Website veröffentlicht. Änderungen gelten ab ihrer Publikation auf unserer Website. Wir empfehlen daher, diese Seite regelmäßig zu besuchen, um sich über gegebenenfalls erfolgte Aktualisierungen zu informieren.</p>
                <p>Stand: 27.08.2021.</p>
            </div>
        )
    }
}

export default Privacy