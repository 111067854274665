import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from "react-router-dom"
import ScrollToTop from "./components/ScrollToTop"
import CookieConsent from "react-cookie-consent"

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <CookieConsent buttonText="Okay">Wir verwenden Cookies.</CookieConsent>
      <ScrollToTop />
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
