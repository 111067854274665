import { Component } from "react"

class Impressum extends Component {

    render() {
        return (
            <div className="info">
                <h1>Impressum</h1>

                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>Conrad Felgentreff<br />
                    Softwareentwicklung<br />
                    Heidestrasse 13A<br />
                    10557 Berlin</p>

                <h2>Kontakt</h2>
                <p>E-Mail: kontakt@mood4food-app.de</p>

                <h2>EU-Streitschlichtung</h2>
                <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
            </div>
        )
    }
}

export default Impressum