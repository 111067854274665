import { Component } from "react"

class Contact extends Component {

    render() {
        return (
            <div className="info">
                <h1>Kontakt</h1>
                <h2>Kontaktanfragen an folgende Emailadresse:</h2>
                <p>kontakt@mood4food-app.de</p>
            </div>
        )
    }
}

export default Contact