import Home from "./components/Home"
import Contact from "./components/Contact"
import Impressum from "./components/Impressum"
import Privacy from "./components/Privacy"
import { Switch, Route } from "react-router-dom"
import "./styles/Info.css"

function App() {

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/kontakt" component={Contact} />
        <Route exact path="/impressum" component={Impressum} />
        <Route exact path="/datenschutz" component={Privacy} />
      </Switch>
    </div>
  );
}

export default App;
